html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

// NORMALIZE

h1,
h2,
h3 {
  letter-spacing: 4.6px;
  font-family: "Nunito Sans", sans-serif;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.carousel-arrow {
  height: 1.3rem;
  cursor: pointer;
}

.next-arrow {
  transform: rotate(180deg);
}

.carousel-dots {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  list-style-type: none;

  button {
    height: 12px;
    width: 12px;
    padding: 0;
    margin: 2px;
    cursor: pointer;
    outline: none;
    background-color: #ffffff;
    text-indent: -9000px;
    text-transform: capitalize;
    border: 1px solid #77c4ba;
    border-radius: 4px;
  }

  .slick-active button {
    background-color: #77c4ba;
  }
}

.link {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9rem;
  color: #77c4ba;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  &.regular {
    font-weight: 500;
    font-size: .7rem;
  }

  &:after {
    content: " ";
    border-bottom: 2px solid #77c4ba;
    margin-top: 2px;
    width: 60%;
    min-width: 80px;
    transition: all 0.2s ease-in-out;
  }

  &.-black {
    color: black;
    font-weight: 500;

    &:after {
      border-color: black;
    }
  }

  &.-right {
    &:after {
      align-self: flex-end;

      @media (max-width: 1024px) {
        align-self: center;
      }
    }
  }

  &:hover {
    &:after {
      width: 80%;
      min-width: 100px;
    }
  }

  @media (max-width: 1024px) {
    align-items: center;
  }
}
