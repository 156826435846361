.load-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  grid-template-areas: "a";
  justify-items: center;
  align-items: center;
  .bar {
    width: 250px;
    grid-area: a;
    z-index: -1;
    animation: spin 1.5s linear infinite;
  }
  .house {
    width: 75%;
    z-index: 1;
    grid-area: a;
    height: 100px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); 
  }
  25% {
    transform: rotate(45deg); 
  }
  50%{
    transform: rotate(200deg); 
  }
  100% {
    transform: rotate(360deg);  
  }
}
