.cookie-consent-container {
    width: 85%;
    background-color: #183534;
    color: white;
    align-items: center;
    transform-origin: top;
    transition: all 0.3s ease;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 300;
    display: flex;
    justify-content: space-between;
    padding: 10px 7.5%;
    font-size: 0.9rem;
    font-weight: 300;
    border-top: 1px solid #183534;
    opacity: 0.85;
    text-align: left;

    @media (max-width: 600px) {
        flex-direction: column;
        font-size: 0.8rem;
        text-align: center;
    }

    .close-btn {
        padding: 7.5px 20px;
        border: 1px solid rgba(255,255,255,0.2);
        border-radius: 30px;
        margin: 0;
        font-size: 0.8rem;
        cursor: pointer;
        margin-left: 40px;
        transition: all 0.3s ease;

        &:hover {
            background: rgba(255,255,255,0.2);
        }

        @media (max-width: 600px) {
            flex-direction: column;
            font-size: 0.7rem;
            margin: 0 0 15px 0;
        }
    }

    a, a:link, a:visited, a:hover, a:active {
        text-decoration: underline;
        color: white;

    }
  }